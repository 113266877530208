import React from 'react';
import './PayInfo.css';
import Top from '../../components/Top/Top';
import Instagram from '../../components/Instagram/Instagram';

export default function PayInfo() {
  return (
    <>
      <div className='pay-info'>
        <div className='pay-cloud'>
          <img className='img' src="./img/clouds.png" alt="хмара" />
        </div>

        <div className='pay-info-image-one'>
          <img className='img' src="./img/star.svg" alt="зірка" />
        </div>

        <div className='pay-info-image-two'>
          <img className='img' src="./img/star.svg" alt="зірка" />
        </div>

        <div className='pay-info-image-three'>
          <img className='img' src="./img/star.svg" alt="зірка" />
        </div>

        <div className='pay-info-image-four'>
          <img className='img' src="./img/star.svg" alt="зірка" />
        </div>

        <h2 className='pay-info__title'>Доставка та оплата</h2>
        <div className='pay-info__line'>
          <img className='img' src="./img/line.svg" alt="хвиля" />
        </div>

        <div className='pay-info__wrapper'>
          <h3 className='pay-info__wrapper-title'>1. Важливе прохання.</h3>
          <p className='pay-info__wrapper-description'>При отриманні замовлення, оглянути і перевірити упакування на наявність пошкоджень при транспортуванні.</p>
          <p className='pay-info__wrapper-description'>У разі виявлення суттєвих дефектів, що могли спричинити псування товару, зафіксувати інцидент, оформивши претензійний лист до перевізника.</p>
          <p className='pay-info__wrapper-description'>Лише за таких умов транспортна компанія відшкодує кошти за доставку. Дякуємо!</p>
        </div>

        <div className='pay-info__line'>
          <img className='img' src="./img/line.svg" alt="хвиля" />
        </div>

        <div className='pay-info__wrapper'>
          <h3 className='pay-info__wrapper-title'>2. Умови даставки.</h3>
          <p className='pay-info__wrapper-description'>При замовленні любого товару на сайті Tuttiksds.top або на сторінці в Інстагарам  <a href="https://www.instagram.com/tuttikids.top/" target='blank'>@tuttiksds.top</a>, Вы отримуєте безкоштовну доставку (ми сплатимо її власним коштом).</p>
          <p className='pay-info__wrapper-description'>Адресна доставка обговорюється окремо.</p>
          <p className='pay-info__wrapper-description'> Доставка товару по всій Україні здійснюється виключно перевізником «Нова Пошта» на найближче до Вас вантажне відділення (більше 30 кг об’ємної ваги).
            Замовлення відправляється протягом 1-2 днів, якщо товар є в наявності. За відсутності бажаного товару, термін узгоджується.
            При отриманні замовлення необхідно мати при собі номер товарно-транспортної накладної (ТТН), що ми надамо після відправки, та документ, що посвідчує особу.</p>
        </div>

        <div className='pay-info__line'>
          <img className='img' src="./img/line.svg" alt="хвиля" />
        </div>

        <div className='pay-info__wrapper'>
          <h3 className='pay-info__wrapper-title'>3. Умови оплати.</h3>
          <p className='pay-info__wrapper-description'>Оплата замовлення (накладений платіж) здійснюється при отриманні та огляді товару на відділенні транспортної компанії «Нова Пошта». Комісію за накладений платіж - 20 грн + 2% від суми переказу, Ви сплачуєте самостійно.</p>
          <p className='pay-info__wrapper-description'>За бажанням, оплату також можна здійснити на рахунок ПриватБанк чи MonoBank. Реквізити карткового рахунку ми повідомимо Вам додатково.</p>
        </div>

        <div className='pay-info__line'>
          <img className='img' src="./img/line.svg" alt="хвиля" />
        </div>

      </div>
      <Top popular={true} />
      <Instagram />
    </>
  );
}
