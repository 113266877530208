import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../Context';
import Top from '../../components/Top/Top';
import Instagram from '../../components/Instagram/Instagram';
import './Stock.css';

export default function Stock() {
  const { data } = useContext(Context);
  const [products, setProducts] = useState(false);

  useEffect(() => {
    if (data) setProducts(data.stock);
  }, [data]);

  return (
    <>
      <div className='stock'>
        <div className='stock-cloud'>
          <img className='img' src="./img/clouds.png" alt="хмара" />
        </div>

        <div className='pay-info-image-one'>
          <img className='img' src="./img/star.svg" alt="зірка" />
        </div>

        <div className='stock-image-two'>
          <img className='img' src="./img/star.svg" alt="зірка" />
        </div>

        {products && products.map((item, index) => (
          <div key={index} className='stock-item'>
            {index % 2 === 0 ? (
              <div className='stock-item-image-three'>
                <img className='img' src="./img/arrow-white.svg" alt="стрілка" />
              </div>
            ) : (
              <div className='stock-item-image-two'>
                <img className='img' src="./img/heard-white.svg" alt="серце" />
              </div>
            )}

            <img className='stock-item__back-img' src="./img/news-back.png" alt="задній фон" />

            <div className='stock-item__main-img'>
              <img className='img-cover' src={item.img[0]} alt="новина" />
            </div>

            <h4 className='stock-item__title'>{item.text}</h4>
            <h5 className='stock-item__description'>{item.description}</h5>
          </div>
        ))}

      </div>
      <Top popular={true} />
      <Instagram />
    </>
  );
}
