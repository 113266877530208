import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Container from './Container/Container';
import Category from './Category/Category';
import Item from './Item/Item';
import Admin from './Admin/Admin';
import Setting from './Admin/Setting/Setting';
import Basket from './Basket/Basket';
import PayInfo from './PayInfo/PayInfo';
import About from './About/About';
import Order from './Order/Order';
import NotFound from './NotFound/NotFound';
import Stock from './Stock/Stock';
import CategoryItem from './CategoryItem/CategoryItem';

export default function MainRoute() {
  return (
    <Container>
      <Routes>
        <Route path='/' element={<Category />} />
        <Route path='/childrens-beds' element={<CategoryItem />} />
        <Route path='/item' element={<Item />} />
        <Route path='/admin' element={<Admin />} />
        <Route path='/setting' element={<Setting />} />
        <Route path='/mattresses' element={<CategoryItem />} />
        <Route path='/dressers' element={<CategoryItem />} />
        <Route path='/bed-sheets' element={<CategoryItem />} />
        <Route path='/basket' element={<Basket />} />
        <Route path='/delivery-and-payment' element={<PayInfo />} />
        <Route path='/about-us' element={<About />} />
        <Route path='/order' element={<Order />} />
        <Route path='/stock' element={<Stock />} />
        <Route path='/*' element={<NotFound />} />
      </Routes>
    </Container>
  );
}
