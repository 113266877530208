import React from 'react';
import { Link } from 'react-scroll';
import './Pagination.css';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  // Генерируем список ссылок на страницы
  const renderPageNumbers = () => {
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i += 1) {
      pageNumbers.push(
        <Link
          smooth={true}
          duration={800}
          offset={-80}
          to='products'
          className={`pagination-number ${currentPage === i ? 'pagination-active' : ''}`}
          key={i}
          onClick={() => onPageChange(i)}
        >
          {i}
        </Link>,
      );
    }

    return pageNumbers;
  };

  return (
    <>
      {totalPages > 1
        ? <div className="pagination">

          {currentPage > 1 ? <Link to='products' smooth={true} duration={800} offset={-80} className='pagination-arrow-md' onClick={() => onPageChange(currentPage - 1)}>
            <img className='img' src="/img/pagination-arrow.svg" alt="стрілка" />
          </Link>
            : <div className='pagination-arrow__off'></div>
          }

          {renderPageNumbers()}

          {currentPage < totalPages ? <Link to='products' smooth={true} duration={800} offset={-80} className='pagination-arrow' onClick={() => onPageChange(currentPage + 1)}>
            <img className='img' src="/img/pagination-arrow.svg" alt="стрілка" />
          </Link>
            : <div className='pagination-arrow__off'></div>
          }
        </div>
        : ''
      }
    </>
  );
};

export default Pagination;
