import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Context } from '../../Context';
import './Footer.css';

export default function Footer() {
  const { setSafeFilter, setSafePage } = useContext(Context);
  const isIphone = useMediaQuery({ maxWidth: 576 });
  const navigate = useNavigate();

  const handleLinkClick = (linkName) => {
    navigate(linkName);
    setSafePage(false);
    setSafeFilter(false);
  };

  return (
    <div className="footer">
      <div className="footer-line">
        <img className="img" src="./img/footer.svg" alt="хвиля" />
      </div>

      <div className="footer-wrapper">
        <div className="footer-wrapper__logo">
          <Link
            smooth={true}
            duration={800}
            to="header"
            onClick={() => handleLinkClick("/")}
            className="footer-wrapper__logo-picture"
          >
            <img className="img" src="./img/logo-footer.svg" alt="Tutti Kids" />
          </Link>

          <div className="footer-wrapper__logo-social">
            <a
              href="https://www.instagram.com/tuttikids.top/"
              target="blank"
              className="footer-wrapper__logo-social-picture"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="https://t.me/Viktoriia_tuttikids" target="blank">
              <FontAwesomeIcon
                icon={faTelegram}
                className="footer-wrapper__logo-social-picture"
              />
            </a>

            <a href="mailto:tuttikidstop@gmail.com" target="blank">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="footer-wrapper__logo-social-picture"
              />
            </a>
          </div>
        </div>

        <div className="footer-wrapper__info footer-wrapper__info-mobile">
          <Link
            to="header"
            onClick={() => handleLinkClick("/about-us")}
            className="footer-wrapper__info-link"
          >
            ПРО НАС
          </Link>
          <Link
            to="header"
            onClick={() => handleLinkClick("/delivery-and-payment")}
            className="footer-wrapper__info-link"
          >
            ДОСТАВКА ТА ОПЛАТА
          </Link>
          <Link
            to="header"
            onClick={() => handleLinkClick("/stock")}
            className="footer-wrapper__info-link"
          >
            АКЦІЇ
          </Link>
          {isIphone ? (
            <Link
              to="instagram"
              smooth={true}
              duration={800}
              offset={40}
              className="footer-wrapper__info-link"
            >
              НАШІ БЕСТСЕЛЕРИ
            </Link>
          ) : (
            <Link
              to="instagram"
              smooth={true}
              duration={800}
              offset={100}
              className="footer-wrapper__info-link"
            >
              НАШІ БЕСТСЕЛЕРИ
            </Link>
          )}
        </div>

        <div className="footer-wrapper__info">
          <Link
            to="header"
            onClick={() => handleLinkClick("/childrens-beds")}
            className="footer-wrapper__info-link"
          >
            ДИТЯЧІ ЛІЖКА
          </Link>
          <Link
            to="header"
            onClick={() => handleLinkClick("/mattresses")}
            className="footer-wrapper__info-link"
          >
            МАТРАСИ ТА НАМАТРАСНИКИ
          </Link>
          <Link
            to="header"
            onClick={() => handleLinkClick("/dressers")}
            className="footer-wrapper__info-link"
          >
            КОМОДИ
          </Link>
          <Link
            to="header"
            onClick={() => handleLinkClick("/bed-sheets")}
            className="footer-wrapper__info-link"
          >
            ПОСТІЛЬ
          </Link>
        </div>
      </div>
      <a href="https://circle.in.ua" target="blank" className="footer-by">
        by circle studio 2023
      </a>
    </div>
  );
}
