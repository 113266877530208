import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyBryouXJ0eZPg00eWBTv6JkcKUsl51Bf40',
  authDomain: 'tutti-kids.firebaseapp.com',
  projectId: 'tutti-kids',
  storageBucket: 'tutti-kids.appspot.com',
  messagingSenderId: '814249888774',
  appId: '1:814249888774:web:10f38fb59f01ad316c4787',
  measurementId: 'G-DG4NX6FJYR',
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
