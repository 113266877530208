import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useMediaQuery } from 'react-responsive';
import Top from '../../components/Top/Top';
import Instagram from '../../components/Instagram/Instagram';
import { Context } from '../../Context';
import './Basket.css';

export default function Basket() {
  const { language, data, setBusketNumber } = useContext(Context);
  const [products, setProducts] = useState([]);
  const [openTime, setOpenTime] = useState(false);
  const [openTimeTwo, setOpenTimeTwo] = useState(false);
  const [filterSize, setFilterSize] = useState(false);

  // размер экрана
  const isIpad = useMediaQuery({ maxWidth: 992 });
  const isIphone = useMediaQuery({ maxWidth: 576 });

  useEffect(() => {
    if (isIpad && !isIphone) {
      setFilterSize('2.5vw !important');
    } else if (isIphone && isIpad) {
      setFilterSize('5vw !important');
    } else {
      setFilterSize('1.5vw !important'); // Если ни одно из условий не выполняется
    }
  }, [isIpad, isIphone]);

  // открыть корзину
  useEffect(() => {
    setTimeout(() => {
      setOpenTime(true);
    }, 700);

    setTimeout(() => {
      setOpenTimeTwo(true);
    }, 1000);

    const cartItems = localStorage.getItem('tuttiItems');
    if (cartItems && data) {
      const parsedCartItems = JSON.parse(cartItems);
      const countMap = {};
      parsedCartItems.forEach((id) => {
        countMap[id] = (countMap[id] || 0) + 1;
      });
      const updatedProducts = data.products
        .filter((product) => countMap[product.id])
        .map((product) => ({
          ...product,
          quantity: countMap[product.id],
        }));

      setProducts(updatedProducts);
    }
  }, [data]);

  // кнопка минус
  const decreaseQuantity = (index) => {
    const currentQuantity = products[index].quantity;
    if (currentQuantity > 1) {
      const newQuantity = currentQuantity - 1;
      const newProducts = [...products];
      newProducts[index] = {
        ...newProducts[index],
        quantity: newQuantity,
      };
      setProducts(newProducts);

      const cartItems = localStorage.getItem('tuttiItems');
      const parsedCartItems = JSON.parse(cartItems);

      if (parsedCartItems) {
        const indexToRemove = parsedCartItems.indexOf(products[index].id);
        parsedCartItems.splice(indexToRemove, 1);
        setBusketNumber(parsedCartItems.length);
        localStorage.setItem('tuttiItems', JSON.stringify(parsedCartItems));
      }
    }
  };

  // кнопка плюс
  const increaseQuantity = (index) => {
    const currentQuantity = products[index].quantity;
    const newQuantity = currentQuantity + 1;
    const newProducts = [...products];
    newProducts[index] = {
      ...newProducts[index],
      quantity: newQuantity,
    };
    setProducts(newProducts);

    const cartItems = localStorage.getItem('tuttiItems');
    const parsedCartItems = JSON.parse(cartItems);

    if (parsedCartItems) {
      const indexToPlus = products[index].id;
      parsedCartItems.push(indexToPlus);
      setBusketNumber(parsedCartItems.length);
      localStorage.setItem('tuttiItems', JSON.stringify(parsedCartItems));
    }
  };

  const removeItem = (index) => {
    const updatedProducts = products.filter((product) => product.id !== products[index].id);
    setProducts(updatedProducts);

    const cartItems = localStorage.getItem('tuttiItems');
    const parsedCartItems = JSON.parse(cartItems);

    if (parsedCartItems) {
      const updatedProducts = parsedCartItems.filter((number) => number !== products[index].id);
      setBusketNumber(updatedProducts.length);
      localStorage.setItem('tuttiItems', JSON.stringify(updatedProducts));
    }
  };

  const calculateTotalItem = (item) => {
    const total = item.price * item.quantity;
    return total;
  };

  const calculateTotalAmount = () => {
    let total = 0;
    for (let i = 0; i < products.length; i += 1) {
      total += products[i].price * products[i].quantity;
    }
    return total;
  };

  const navigate = useNavigate();

  const handleLinkClick = (linkName) => {
    navigate(linkName);
  };

  return (
    <>
      <div className='basket'>

        <div className='item-cloud'>
          <img className='img' src="./img/clouds.png" alt="хмара" />
        </div>

        <div className='basket-image-one'>
          <img className='img' src="./img/star.svg" alt="зірка" />
        </div>

        <div className='basket-image-two'>
          <img className='img' src="./img/star.svg" alt="зірка" />
        </div>

        <div className='basket-image-three'>
          <img className='img' src="./img/star.svg" alt="зірка" />
        </div>

        <div className='basket-image-four'>
          <img className='img' src="./img/star.svg" alt="зірка" />
        </div>

        <div className='basket-wrapper'>
          {openTime && <div className='basket-title'>{language === 'ua' ? 'Ваш кошик' : 'Your shopping cart'}</div>}

          {openTime && <div className='basket-line'>
            <img className='img' src="./img/line.svg" alt="лінія" />
          </div>}

          {openTime && products.map((item, index) => (
            <div key={index}>
              <div className='basket-item' >
                {isIphone && <div className='basket-item__delete' onClick={() => removeItem(index)} >
                  <img className='img' src="./img/x.svg" alt="хрестик" />
                </div>}
                <div className='basket-item__picture'>
                  <img className='img-cover' src={item.img[0]} alt="кошик" />
                </div>
                <div className='basket-item__name'>{language === 'ua' ? item.titleUa : item.titleEn}</div>
                <div className='basket-item__plus-minus'>
                  <div className='basket-item__plus-minus__img'>
                    <img className='img' src='./img/number.svg' alt="мінус" />
                  </div>
                  <RemoveIcon sx={{ cursor: 'pointer', color: '#526f9f', fontSize: filterSize }} onClick={() => decreaseQuantity(index)} />
                  <div className='basket-item__plus-minus-number'>{item.quantity}</div>
                  <AddIcon sx={{ cursor: 'pointer', color: '#526f9f', fontSize: filterSize }} onClick={() => increaseQuantity(index)} />
                </div>
                <div className='basket-item__cost'>{calculateTotalItem(item)} {language === 'ua' ? 'грн' : 'uah'}</div>
                {!isIphone && <div className='basket-item__delete' onClick={() => removeItem(index)} >
                  <img className='img' src="./img/x.svg" alt="кошик" />
                </div>}
              </div>
              <div className='basket-line'>
                <img className='img' src="./img/line.svg" alt="лінія" />
              </div>
            </div>))}

          {openTimeTwo && <div className='basket-sum__wrapper'>
            <div className='basket-sum'>{language === 'ua' ? 'Сума до сплати:' : 'Amount due'}</div>

            <div className='basket-sum__number__wrapper'>
              <div className='basket-sum__number__img'>
                <img className='img' src="./img/oval.svg" alt="овал" />
              </div>
              <div className='basket-sum__number'>{calculateTotalAmount()} {language === 'ua' ? 'грн' : 'uah'}</div>
            </div>
          </div>}

          {openTimeTwo && <Link to='header' onClick={() => handleLinkClick('/order')} className='basket-button__wrapper'>
            <div className='basket-button__img'>
              <img className='img' src="./img/basket-blue.svg" alt="кошик" />
            </div>
            <div className='basket-button__text'>{language === 'ua' ? 'Перейти до замовлення' : 'Go to payment'}</div>

          </Link>}
        </div>
      </div>

      <Top popular={true} />
      <Instagram />
    </>
  );
}
