import React, { useContext } from 'react';
import ProductsMap from '../ProductsMap/ProductsMap';
import { Context } from '../../Context';
import './Top.css';

export default function Top({ category, popular }) {
  const { language } = useContext(Context);
  return (
    <div className='top'>

      <div className='top-cloud'>
        <img className='img' src="./img/clouds-white.svg" alt="тучка" />
      </div>

      <h3 className='top-title'>{language === 'ua' ? 'Наші бестселери' : 'Our bestsellers'}</h3>
      <div className='top-moon'>
        <img className='img' src="./img/moon.svg" alt="луна" />
      </div>
      <div className='top-star'>
        <img className='img' src="./img/star.svg" alt="зірка" />
      </div>
      <div className='top-star__small'>
        <img className='img' src="./img/star-small.svg" alt="зірка" />
      </div>
      <div className='top-star__right'>
        <img className='img' src="./img/star.svg" alt="зірка" />
      </div>

      <div className='top-wrapper'>

        <ProductsMap category={category} popular={popular} />

      </div>

      <div className='top-cloud__bottom'>
        <img className='img' src="./img/clouds-white.svg" alt="тучка" />
      </div>

    </div>
  );
}
