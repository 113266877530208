import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { Context } from '../../Context';
import './Header.css';

export default function Header() {
  const {
    language,
    basketNumberLocal,
    busketNumber,
    setBusketNumber,
  } = useContext(Context);
  const [busketNumberCorrect, setBusketNumberCorrect] = useState(0);
  const [busketBigIcon, setBusketBigIcon] = useState(false);
  const [busketFull, setBusketFull] = useState(false);
  const [burgerActive, setBurgerActive] = useState(false);
  const [phoneActive, setPhoneActive] = useState(false);
  const { pathname } = window.location;
  const navigate = useNavigate();

  const isIphone = useMediaQuery({ maxWidth: 992 });

  // const handleLenguage = (text) => {
  //     setLanguage(text)
  //     if (text === 'ua') {
  //         localStorage.removeItem("selectedLanguage");
  //     } else {
  //         localStorage.setItem('selectedLanguage', 'eng');
  //     }
  // };

  const location = useLocation();
  useEffect(() => {
    setBurgerActive(false);
  }, [location]);

  useEffect(() => {
    const loadCartItems = async () => {
      const cartItems = await localStorage.getItem('tuttiItems');
      if (cartItems) {
        setBusketNumber(JSON.parse(cartItems).length);
      }
    };
    loadCartItems();
  }, [setBusketNumber]);

  useEffect(() => {
    setBusketNumberCorrect(basketNumberLocal.length);

    setTimeout(() => {
      if (busketNumber === 0 && pathname === '/item') {
        setTimeout(() => {
          setBusketBigIcon(true);
        }, 50);

        setTimeout(() => {
          setBusketBigIcon(false);
        }, 400);
      }
      if (busketNumber > 0 && pathname === '/item') {
        setBusketBigIcon(true);
        setTimeout(() => {
          setBusketBigIcon(false);
        }, 300);
      }

      setBusketNumber((prevBusketNumber) => prevBusketNumber + basketNumberLocal.length - busketNumberCorrect);
    }, 1200);
    // eslint-disable-next-line
  }, [basketNumberLocal, pathname]);

  const handleBusketFullClick = () => {
    setBusketFull(true);
    setTimeout(() => {
      setBusketFull(false);
    }, 1200);
  };

  const handleBurgerActive = () => {
    setBurgerActive(!burgerActive);
  };

  const handlePhoneActive = () => {
    setPhoneActive(!phoneActive);
  };

  useEffect(() => {
    setPhoneActive(false);
  }, [pathname]);

  const handleLinkClick = () => {
    if (pathname === '/basket') {
      navigate('/order');
    } else {
      navigate('/basket');
    }
  };

  return (
    <header id="header" className="header">
      <div className="header-logo">
        <div className="header-logo__icons">
          {busketNumber > 0 ? (
            <div
              onClick={handleLinkClick}
              className="header-logo__icons-picture"
            >
              <div
                className={`header-logo__busket-number ${
                  busketBigIcon ? "header-logo__busket-number-active" : ""
                }`}
              >
                {busketNumber}
              </div>
              <img className="img" src="../img/basket.svg" alt="кошик" />
            </div>
          ) : (
            <div
              onClick={handleBusketFullClick}
              className="header-logo__icons-picture"
            >
              <img
                className={`header-logo__icons-img ${
                  busketFull ? "header-logo__icons-img-active" : ""
                }`}
                src="../img/empty.svg"
                alt="кошик"
              />
              <img
                className="img header-logo__item"
                src="../img/basket.svg"
                alt="кошик"
              />
            </div>
          )}
          <a
            href="https://www.instagram.com/tuttikids.top/"
            target="blank"
            id="header-instagram-icon"
            className="header-logo__icons-picture header-logo__item"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>

        <Link to="/" className="header-logo__picture header-logo__item">
          <img
            className="header-logo__img"
            src="../img/logo.svg"
            alt="логотип"
          />
        </Link>
        {/* <div className='header-logo__language'>

                    <div className='header-logo__language-picture'>
                        <div onClick={() => handleLenguage('ua')} className={`header-logo__language-text ${language === 'ua' ? 'header-logo__language-text-active' : ''}`}>{language === 'ua' ? 'УКР' : 'UA'}</div>
                        {language === 'ua' && <img className='header-logo__language-img' src="../img/language.svg" alt="" />}
                    </div>

                    <div className='header-logo__language-picture'>
                        <div onClick={() => handleLenguage('eng')} className={`header-logo__language-text ${language === 'eng' ? 'header-logo__language-text-active' : ''}`}>ENG</div>
                        {language === 'eng' && <img className='header-logo__language-img' src="../img/language.svg" alt="" />}
                    </div>
                </div> */}
        <div className="header-logo__phone-wrapper">
          {!isIphone && (
            <div
              className={`header-logo__phone-number ${
                phoneActive ? "header-logo__phone-number-active" : ""
              }`}
            >
              <a href="tel:+380681100532">068 110 05 32</a>
            </div>
          )}
          {!isIphone ? (
            <div
              onClick={handlePhoneActive}
              className={`header-logo__phone ${
                phoneActive ? "header-logo__phone-active" : ""
              }`}
            >
              <FontAwesomeIcon
                icon={faPhone}
                className="header-logo__phone-icon"
              />
            </div>
          ) : (
            <a href="tel:+380681100532">
              <FontAwesomeIcon
                icon={faPhone}
                className="header-logo__phone-icon"
              />
            </a>
          )}
          <a href="https://t.me/Viktoriia_tuttikids" target="blank">
            <FontAwesomeIcon
              icon={faTelegram}
              className="header-logo__phone-icon header-icon-md"
            />
          </a>
        </div>

        <div className="header-logo__burger__wrapper">
          <div
            onClick={handleBurgerActive}
            className={`header-logo__burger ${
              burgerActive ? "header-logo__burger-active" : ""
            }`}
          >
            <div className="header-logo__burger-line"></div>
            <div className="header-logo__burger-line"></div>
            <div className="header-logo__burger-line"></div>
          </div>
        </div>
      </div>

      <div
        className={`header-buttons ${
          burgerActive ? "header-buttons__active" : ""
        }`}
      >
        <div className="header-buttons__burger-links">
          <a href="tel:+380681100532">
            <FontAwesomeIcon
              icon={faPhone}
              className="header-logo__phone-icon"
            />
          </a>

          <a href="https://www.instagram.com/tuttikids.top/" target="blank">
            <FontAwesomeIcon
              icon={faInstagram}
              className="header-logo__phone-icon header-icon-md"
            />
          </a>

          <a href="https://t.me/Viktoriia_tuttikids" target="blank">
            <FontAwesomeIcon
              icon={faTelegram}
              className="header-logo__phone-icon header-icon-md"
            />
          </a>
        </div>
        <Link to="/about-us" className="header-buttons__text">
          {language === "ua" ? "ПРО НАС" : "ABOUT US"}
        </Link>
        <Link to="/delivery-and-payment" className="header-buttons__text">
          {language === "ua" ? "ДОСТАВКА ТА ОПЛАТА" : "DELIVERY AND PAYMENT"}
        </Link>
        <Link to="/stock" className="header-buttons__text">
          {language === "ua" ? "АКЦІЇ" : "GOODS"}
        </Link>
      </div>
    </header>
  );
}
