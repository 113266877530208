import React from 'react';
import './Container.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

export default function Container({ children }) {
  return (
    <div className='container'>
      <Header />
      {children}
      <Footer />
    </div>
  );
}
