import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { firestore } from './firebase';
import MainRoute from './pages/MainRoute';
import { Context } from './Context';
import DeleteButton from './pages/Admin/DeleteAdmin';
import './App.css';
import './fonts.css';

export default function App() {
  const [language, setLanguage] = useState('ua');
  const [data, setData] = useState(null);
  const [basketNumberLocal, setBasketNumberLocal] = useState([]);
  const [busketNumber, setBusketNumber] = useState(0);
  const [safeFilter, setSafeFilter] = useState(false);
  const [safePage, setSafePage] = useState(false);

  useEffect(() => {
    const usersCollection = firestore.collection('data');
    usersCollection.get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          setData(data);
        });
        return null;
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.error('Ошибка при получении данных:', error);
      });
  }, []);

  const hasAuth = localStorage.getItem('authTutti') !== null;

  return (
    <Context.Provider value={{
      data,
      language,
      setLanguage,
      basketNumberLocal,
      setBasketNumberLocal,
      busketNumber,
      setBusketNumber,
      safeFilter,
      setSafeFilter,
      safePage,
      setSafePage,
    }}>
      <BrowserRouter>
        <Routes>
          <Route path='/*' element={<MainRoute />} />
        </Routes>
        {hasAuth && <DeleteButton />}
      </BrowserRouter>
    </Context.Provider>
  );
}
