import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';
import { Context } from '../../Context';
import Top from '../../components/Top/Top';
import Instagram from '../../components/Instagram/Instagram';
import './Category.css';

export default function Category() {
  const { language, setSafeFilter, setSafePage } = useContext(Context);
  const [start, setStart] = useState(false);
  const [startOne, setStartOne] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setStart(true);
    }, 100);
    setTimeout(() => {
      setStartOne(true);
    }, 900);
  }, []);

  const [isTwo, setTwo] = useState(false);
  const [isTwoActive, setTwoActive] = useState(false);
  const [isThree, setThree] = useState(false);
  const [isFour, setFour] = useState(false);

  const handleMouseEnterTwo = () => {
    if (!isTwoActive) {
      setTwo(true);
      setTimeout(() => {
        setTwo(false);
        setTwoActive(true);
      }, 1000);
    }
  };

  const handleMouseEnterThree = () => {
    setThree(true);
  };
  const handleMouseEnterFour = () => {
    setFour(true);
  };

  const navigate = useNavigate();

  const handleLinkClick = (linkName) => {
    navigate(linkName);
    setSafePage(false);
    setSafeFilter(false);
  };

  return (
    <>
      <div className='category'>

        <div className={`category-cloud__main ${start ? 'category-cloud__active' : ''}`}>
          <img className='img' src="./img/clouds.png" alt="Tutti Kids" />
        </div>

        <Link className={`category-one ${start ? 'category-one__active' : ''}`} to='header' onClick={() => handleLinkClick('/childrens-beds')}>

          <div className={`category-one__wrapper ${startOne ? 'category-one__wrapper__active' : ''}`}>

            <div className='category-one__wrapper-picture'>
              <img className='img-cover' src="./img/category-one.jpg" alt="дитячі ліжка" />
              <img className='category-one__wrapper-picture__background' src="./img/item-background-one.svg" alt="синий" />
            </div>

            <div className='category-one__wrapper-info'>
              <h1 className='category-one__wrapper-info-title'>{language === 'ua' ? 'Дитячі ліжка' : 'Children`s beds'}</h1>
              <h2 className='category-one__wrapper-info-description'>{language === 'ua' ? 'Ліжко для новонародженого - важлива частина дитячого світу, це безпечне і комфортне середовище для сну та відпочинку дитини.'
                : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sodales sed orci ut volutpat.'}</h2>
            </div>

          </div>

          <div className='category-one__image-one'>
            <img className='img' src="./img/star.svg" alt="звездочка" />
          </div>

          <div className='category-one__image-two'>
            <img className='img' src="./img/star.svg" alt="звездочка" />
          </div>

          <div className='category-one__image-three'>
            <img className='img' src="./img/moon.svg" alt="звездочка" />
          </div>

          <div className='category-one__image-four'>
            <img className='img' src="./img/star.svg" alt="звездочка" />
          </div>

          <div className='category-one__image-five'>
            <img className='img' src="./img/star.svg" alt="звездочка" />
          </div>

        </Link>

        <Link onMouseEnter={handleMouseEnterTwo} to='header' onClick={() => handleLinkClick('/mattresses')} className='category-two'>

          <div className={`category-two__wrapper ${startOne ? 'category-one__wrapper__active' : ''}`}>

            <div className='category-two__wrapper-info'>
              <h2 className='category-two__wrapper-info-title'>{language === 'ua' ? 'Матраси та наматрасники' : 'Mattresses and mattress pads'}</h2>
              <h3 className='category-two__wrapper-info-description'>{language === 'ua' ? 'Якісний матрац необхідний для правильного фізичного розвитку малюка та здорового і спокійного сну.' : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sodales sed orci ut volutpat.'}</h3>
            </div>

            <div className='category-two__wrapper-picture'>
              <img className='img-cover' src="./img/category-two.jpg" alt="матраси та наматрасники" />
              <img className='category-two__wrapper-picture__background' src="./img/item-background-two.svg" alt="блакитний" />
            </div>

          </div>

          <div className='category-two__image-one'>
            {isTwo ? <img className='img' src="./img/oval.gif" alt="овал" /> : ''}
            {isTwoActive ? <img className='img' src="./img/oval-img.png" alt="овал" /> : ''}
          </div>

          <div className={`category-two__image-two ${startOne ? 'category-one__wrapper__active' : ''}`}>
            <img className='img' src="./img/crooked-arrow.svg" alt="стрілка" />
          </div>

        </Link>

        <Link onMouseEnter={handleMouseEnterThree} to='header' onClick={() => handleLinkClick('/dressers')} className='category-three'>

          <div className={`category-three__wrapper ${startOne ? 'category-one__wrapper__active' : ''}`}>

            <div className='category-three__wrapper-picture'>
              <img className='img-cover' src="./img/category-three.jpg" alt="комоди" />
              <img className='category-three__wrapper-picture__background' src="./img/item-background-three.svg" alt="блакитний" />
            </div>

            <div className='category-three__wrapper-info'>
              <h2 className='category-three__wrapper-info-title'>{language === 'ua' ? 'Комоди' : 'Dressers'}</h2>
              <h3 className='category-three__wrapper-info-description'>{language === 'ua' ? 'Комод-пеленатор поєднує в собі зручність і функціональність, маючи спеціальну поверхню для догляду за малюком та місткі шухляди для зберігання дитячих речей.'
                : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sodales sed orci ut volutpat.'}</h3>
            </div>
          </div>

          <div className={`category-three__image-one ${startOne ? 'category-one__wrapper__active' : ''} ${isThree ? 'category-three__image-one__active' : ''}`}>
            <img className='img' src="./img/xo-xo.svg" alt="хо-хо" />
          </div>

        </Link>

        <Link onMouseEnter={handleMouseEnterFour} to='header' onClick={() => handleLinkClick('/bed-sheets')} className='category-four'>

          <div className='category-four__wrapper'>

            <div className='category-four__wrapper-info'>
              <h2 className='category-four__wrapper-info-title'>{language === 'ua' ? 'Постіль' : 'Bedding for a bed'}</h2>
              <h3 className='category-four__wrapper-info-description'>{language === 'ua' ? 'Ніжні або яскраві кольори, м`які тканини та аксесуари створять затишок і прикрасять інтер`єр дитячої кімнати.'
                : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sodales sed orci ut volutpat.'}</h3>
            </div>

            <div className='category-four__wrapper-picture'>
              <img className='img-cover' src="./img/category-four.jpg" alt="постиль" />
              <img className='category-four__wrapper-picture__background' src="./img/item-background-two.svg" alt="блакитний" />
            </div>

          </div>

          <div className={`category-four__image-one ${isFour ? 'category-four__image-one__active' : ''}`}>
            <img className='img' src="./img/heart.svg" alt="сердце" />
          </div>

          <div className='category-four__image-two'>
            <img className='img' src="./img/arrow-two.svg" alt="стрілка" />
          </div>
        </Link>
      </div>

      <Top category={false} popular={true} />
      <Instagram />

    </>
  );
}
