import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { Button } from '@mui/material';
import { Context } from '../../Context';
import { InputText } from '../../components/Forms/InputText';
import { addFormRules } from '../../constans/rules';
import './Order.css';

export default function Order() {
  const { control, handleSubmit, getValues } = useForm();
  const { data, setBusketNumber } = useContext(Context);
  const [items, seTitems] = useState(false);
  const [final, setFinal] = useState(false);
  const [filterSize, setFilterSize] = useState('.8vw');

  // размер экрана
  const isIpad = useMediaQuery({ maxWidth: 992 });
  const isIphone = useMediaQuery({ maxWidth: 576 });

  useEffect(() => {
    if (isIpad && !isIphone) {
      setFilterSize('2.5vw !important');
    } else if (isIphone && isIpad) {
      setFilterSize('3vw !important');
    } else {
      setFilterSize('.8 !important');
    }
  }, [isIpad, isIphone]);

  // получение при перезагрузке страницы
  useEffect(() => {
    const tuttiItems = localStorage.getItem('tuttiItems');
    if (tuttiItems && data) {
      const parsedCartItems = JSON.parse(tuttiItems);
      const countMap = {};
      parsedCartItems.forEach((id) => {
        countMap[id] = (countMap[id] || 0) + 1;
      });
      const updatedProducts = data.products
        .filter((product) => countMap[product.id])
        .map((product) => ({
          ...product,
          quantity: countMap[product.id],
        }));

      seTitems(updatedProducts);
    }
  }, [data]);

  const calculateTotalAmount = () => {
    let total = 0;
    for (let i = 0; i < items.length; i += 1) {
      total += items[i].price * items[i].quantity;
    }
    return total;
  };

  // отправление данных
  const onSubmit = () => {
    setFinal(true);

    const itemsObject = items.reduce((acc, item, index) => {
      const itemInfo = `Назва товару: ${item.titleUa}.\nId товару: ${item.id}\nЦіна товару: ${item.price}\nКількість: ${item.quantity || 1}\n-----------------`;
      acc[`Товар ${index + 1}`] = itemInfo;
      return acc;
    }, {});

    const {
      name,
      surname,
      email,
      number,
      city,
      mail,
      postOffice,
    } = getValues();

    // Собираем данные для отправки
    const dataToSend = {
      'Ім`я': name,
      Прізвище: surname,
      Номер: number,
      'E-mail': email,
      місто: city,
      пошта: postOffice,
      відділення: mail,
      '----------': '--------',
      ...itemsObject,
    };

    fetch('https://messenger-8mp2.onrender.com/tutti-kids', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToSend),
    })
      .catch((error) => {
        // eslint-disable-next-line
        console.log('Ошибка отправки данных:', error);
      });

    setTimeout(() => {
      localStorage.removeItem('tuttiItems');
      window.location.href = '/';
      setBusketNumber(0);
    }, 2700);
  };

  return (
    <div className='order'>

      <div className='order-cloud'>
        <img className='img' src="./img/clouds.png" alt="хмара" />
      </div>

      <div className='order-input'>
        <div className='order-info__text'>Ваші дані</div>

        <div className='order-info__contacts-title'>Контакти</div>

        <div className='order-info__contacts'>

          <InputText
            control={control}
            name='name'
            label='Ім`я'
            type='text'
            rules={addFormRules.name}
            colors='grey'
            widths='50%'
          />

          <InputText
            control={control}
            name='surname'
            label='Прізвище'
            type='text'
            rules={addFormRules.surname}
            colors='grey'
            widths='50%'
          />
        </div>

        <div className='order-info__contacts-title'>Відправлення</div>

        <div className='order-info__contacts'>

          <InputText
            control={control}
            name='email'
            label='Email'
            type='text'
            rules={addFormRules.mail}
            colors='grey'
            widths='50%'
          />

          <InputText
            control={control}
            name='number'
            label='Телефон +38'
            type='text'
            rules={addFormRules.number}
            colors='grey'
            widths='50%'
          />
        </div>

        <div className='order-info__contacts'>

          <InputText
            control={control}
            name='city'
            label='Населений пункт'
            type='text'
            rules={addFormRules.city}
            colors='grey'
            widths='50%'
          />

          <InputText
            control={control}
            name='postOffice'
            label='Відділення нової пошти'
            type='text'
            rules={addFormRules.postOffice}
            colors='grey'
            widths='50%'
          />
        </div>

        <Button
          sx={{
            color: 'white',
            backgroundColor: '#526F9F',
            fontSize: filterSize,
            marginLeft: 'auto',
            marginTop: '2.5vw',
            transition: '.5s',
            '&:hover': { scale: '1.1', backgroundColor: '#526F9F' },
          }}
          onClick={handleSubmit(onSubmit)}
        >Надіслати
        </Button>

      </div>
      <div className='order-info'>

        <div className='order-info__text'>Деталі замовлення</div>

        {items && items.map((item, index) => (

          <div key={index} className='order-info__item'>

            <div className='order-info__item__img'>
              <img className='img-cover' src={item.img[0]} alt="зображеня товару" />
            </div>

            <div className='order-info__item__name'>{item.titleUa}</div>

            <div className='order-info__item__wrapper'>
              <div className='order-info__item__cost'>{item.price} грн</div>
              <div className='order-info__item__quantity'>кількість {item.quantity}</div>
            </div>

          </div>
        ))}

        <div className='order-info__summ'>
          <div className='order-info__summ-text'>До сплати:</div>
          <div className='order-info__summ-number'>{calculateTotalAmount()} грн</div>
        </div>
      </div>

      {final && <div className='order-image__final'>
        <img className='img' src="./img/thanks.svg" alt="подяка" />
      </div>}

    </div>
  );
}
