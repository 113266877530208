const loadImage = (src) => new Promise((resolve, reject) => {
  const image = new Image();
  image.onload = () => resolve(image);
  image.onerror = (error) => reject(error);
  image.src = src;
});

const loadImages = async (array) => {
  try {
    const imagePromises = array.map((item) => {
      if (Array.isArray(item.img)) {
        return Promise.all(item.img.map((src) => loadImage(src)));
      }
      if (typeof item.img === 'string') {
        return loadImage(item.img).then((image) => [image]);
      }
      return Promise.resolve([]); // Возвращаем разрешенный промис с пустым массивом для некорректных значений
    });

    const loadedImagesArrays = await Promise.all(imagePromises);
    const productsWithImages = array.map((item, index) => ({
      ...item,
      img: loadedImagesArrays[index],
    }));
    return (productsWithImages);
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
  }
};

export { loadImages };
