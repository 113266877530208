import React, { useContext, useEffect, useState } from 'react';
import WindowIcon from '@mui/icons-material/Window';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import { Context } from '../../../Context';
import { storage, firestore } from '../../../firebase';
import RequireAdminAuth from '../RequireAdminAuth';
import './Setting.css';

export default function Setting() {
  const { data } = useContext(Context);
  const [instagram, setInstagram] = useState([]);
  const [stock, setStock] = useState(false);
  const [arrayProduct, setArrayProduct] = useState([]);
  const [progress, setProgress] = useState(false);
  const [progressCarousel, setProgressCarousel] = useState(false);
  const [progressProduct, setProgressProduct] = useState(false);
  const [progressStock, setProgressStock] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [editProduct, setEditProduct] = useState(null);
  const [editImage, setEditImage] = useState([]);
  const [editProductOrigin, setEditProductOrigin] = useState(null);
  const [display, setDisplay] = useState(false);

  const categoryArray = [
    { value: 'childrens-beds', label: 'Дитячі ліжка' },
    { value: 'mattresses', label: 'Матраси та наматрасники' },
    { value: 'dressers', label: 'Комоди' },
    { value: 'bed-sheets', label: 'Постіль' },
  ];

  const subСategoriesArray = [
    { value: 1, label: 'Маятник без шухляди' },
    { value: 2, label: 'На колесах' },
    { value: 3, label: 'Овальні' },
    { value: 6, label: 'Маятник з шухлядою' },
    { value: 4, label: 'Наматрацники' },
    { value: 5, label: 'Без пеленальної поверхні' },
  ];

  const colors = [
    { value: 'white', label: 'Білий' },
    { value: 'gray', label: 'Сірий' },
    { value: 'vanilla', label: 'Слонова кістка(Ваніль)' },
    { value: 'wenge', label: 'Венге' },
    { value: 'nut', label: 'Горіх' },
    { value: 'natural', label: 'Натуральний' },
    { value: 'pink', label: 'Рожевий' },
    { value: 'blue', label: 'Блакитний' },
    { value: 'anthracite', label: 'Антрацит' },
    { value: 'grayAndWhite', label: 'Сіро-білий' },
    { value: 'sonoma', label: 'Дуб сонома' },
    { value: 'alyaska', label: 'Аляска' },
  ];

  const size = [
    { value: 6, label: '6см' },
    { value: 7, label: '7см' },
    { value: 8, label: '8см' },
    { value: 9, label: '9см' },
    { value: 10, label: '10см' },
    { value: 11, label: '11см' },
    { value: 12, label: '12см' },
  ];

  const brands = [
    { value: 'dubik', label: 'Дубик М' },
    { value: 'babyCofort', label: 'Baby Cofort' },
    { value: 'deSon', label: 'DeSon' },
    { value: 'childrensDream', label: 'Children`s Dream' },
    { value: 'kuzy', label: 'Кузя' },
    { value: 'dominata', label: 'Домінанта' },
    { value: 'flitex', label: 'Flitex' },
    { value: 'goodNight', label: 'Солодких снів' },
  ];

  // инстаграм
  const [instagramLink, setInstagramLink] = useState('');
  // новая новость

  const [newStock, setNewStock] = useState('');

  // товар
  const [product, setProduct] = useState({
    // titleEn: '',
    titleUa: '',
    id: '',
    article: '',
    link: '',
    price: '',
    color: '',
    // descriptionEn: '',
    descriptionUa: '',
    // characteristicEn: '',
    characteristicUa: '',
    category: '',
    subСategories: '',
    size: '',
    brand: '',
    popular: '',
  });

  useEffect(() => {
    if (data) {
      setInstagram(data.instagram);
      setArrayProduct(data.products);
      setStock(data.stock);
    }
  }, [data]);

  // добавление фото
  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      const filesArray = Array.from(e.target.files);
      setSelectedFiles(filesArray);
    }
  };
  // галочка на популярные
  const handleInputChange = (event) => {
    const {
      name,
      value,
      type,
      checked,
    } = event.target;

    if (!editProduct) {
      if (type === 'checkbox') {
        setProduct((prevProduct) => ({ ...prevProduct, [name]: checked }));
      } else {
        setProduct((prevProduct) => ({ ...prevProduct, [name]: value }));
      }
    }
  };

  // добвление ссылок инстаграма
  const handleInputChangeInstagram = (event) => {
    const {
      name,
      value,
    } = event.target;
    setInstagramLink((prevProduct) => ({ ...prevProduct, [name]: value }));
  };

  // добвление акций
  const handleInputChangeStock = (event) => {
    const {
      name,
      value,
    } = event.target;
    setNewStock((prevProduct) => ({ ...prevProduct, [name]: value }));
  };

  // кнопка отправки
  const handleUpload = (folderPath, array, product) => {
    if (selectedFiles.length > 0) {
      const uploadPromises = selectedFiles.map((file) => storage
        .ref(`${folderPath}/${file.name}`)
        .put(file)
        .then((snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setProgress(progress);
          // eslint-disable-next-line
          console.log(`Progress: ${progress}%`);
          return storage.ref(folderPath).child(file.name).getDownloadURL();
        }));

      Promise.all(uploadPromises)
        .then((urls) => {
          // eslint-disable-next-line
          console.log('Image URLs:', urls);

          let update = {};
          const updatedArray = array.map((item) => ({ ...item }));
          updatedArray.push({ ...product, img: urls });
          update = { [folderPath]: updatedArray };

          return firestore
            .collection('data')
            .doc('CMEK9tNFjTweJAukAjh2')
            .update(update)
            // eslint-disable-next-line
            .then(() => {
              setProgress('Товар додано');
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log('Ошибка сохранения URLs:', error);
          setProgress('Ошибка добавления файлов');
        });
    }
  };

  // удаление
  const handleDelete = (url, folderPath, array) => {
    const imageRefs = [];
    // Удаляем все фотографии товара из хранилища
    url.forEach((photoUrl) => {
      imageRefs.push(storage.refFromURL(photoUrl).delete());
    });

    Promise.all(imageRefs)
      // eslint-disable-next-line
      .then(() => {
        setProgress('Видалено');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log('Ошибка удаления изображений из хранилища:', error);
        setProgress('Помилка видалення');
      });

    const updatedArray = array.filter((item) => item.img !== url);

    // Удаляем товар из базы данных
    firestore
      .collection('data')
      .doc('CMEK9tNFjTweJAukAjh2')
      .update({
        [folderPath]: updatedArray,
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log('Ошибка удаления изображений из массива в Firestore:', error);
      });
  };

  // фильт для отображения товаров
  const [selectedCategory, setSelectedCategory] = useState('Всі товари');
  const [selectedBrands, setSelectedBrands] = useState('Всі бренди');
  const [selectedColors, setSelectedColors] = useState('Всі кольори');
  const [showPopular, setShowPopular] = useState(false);

  const handleChangeCategory = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleChangeBrands = (event) => {
    setSelectedBrands(event.target.value);
  };

  const handleChangeColors = (event) => {
    setSelectedColors(event.target.value);
  };

  const handleChangeShowPopular = (event) => {
    setShowPopular(event.target.checked);
  };

  let displayedProducts = [];
  if (arrayProduct) {
    displayedProducts = arrayProduct.filter((item) => {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      const lowerCaseTitle = item.titleUa.toLowerCase();

      return (
        (selectedCategory === 'Всі товари' || item.category === selectedCategory)
        && (selectedBrands === 'Всі бренди' || item.brand === selectedBrands)
        && (selectedColors === 'Всі кольори' || item.color === selectedColors)
        && (showPopular ? item.popular : true)
        && lowerCaseTitle.includes(lowerCaseSearchQuery)
      );
    });
  }

  const handleEditInputChange = (e, editProduct) => {
    const {
      name,
      type,
      value,
      checked,
    } = e.target;
    if (type === 'checkbox') {
      setEditProduct({ ...editProduct, [name]: checked });
    } else {
      setEditProduct({ ...editProduct, [name]: value });
    }
  };

  // удаление картинки
  const handleEditImage = (imageToDelete) => {
    // Создайте копию editProduct, чтобы не изменять оригинальный объект
    const updatedProduct = { ...editProduct };

    const imageIndex = updatedProduct.img.indexOf(imageToDelete);

    if (imageIndex !== -1) {
      const deletedImage = updatedProduct.img.splice(imageIndex, 1)[0];
      setEditImage((prevImages) => [...prevImages, deletedImage]); // Добавьте удаленное изображение к editImage
    }

    // Обновите состояние editProduct
    setEditProduct(updatedProduct);
  };

  // Функция для удаления фотографий из Firebase Storage
  const deleteOldImagesFromStorage = (editImage) => {
    editImage.forEach((imageURL) => {
      // Используйте функции Firebase Storage для удаления изображения по его URL
      // Здесь предполагается, что ваша фотография находится в папке 'images'
      const imageRef = storage.refFromURL(imageURL);

      imageRef
        .delete()
        // eslint-disable-next-line
        .then(() => {
          // eslint-disable-next-line
          console.log('Изображение удалено из Storage');
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error('Ошибка при удалении изображения из Storage:', error);
        });
    });
  };
  // Функция для обновления товара в Firestore
  const updateFirestoreProduct = (updatedProduct) => {
    const filteredEditProduct = Object.entries(updatedProduct).reduce((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});

    const updatedArray = arrayProduct.map((item) => {
      if (item === editProductOrigin) {
        return {
          ...item,
          ...filteredEditProduct,
        };
      }
      return item;
    });

    firestore
      .collection('data')
      .doc('CMEK9tNFjTweJAukAjh2')
      // eslint-disable-next-line
      .update({ 'products': updatedArray })
      // eslint-disable-next-line
      .then(() => {
        setProgress('Товар оновлено');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log('Ошибка обновления товара:', error);
        setProgress('Ошибка обновления товара');
      });
  };

  const handleEditProduct = (editProduct) => {
    setProgressProduct(true);
    // Удалите старые фотографии из Firebase Storage
    if (editImage.length > 0) {
      deleteOldImagesFromStorage(editImage);
    }

    // Далее, добавьте новые фотографии, если они есть
    if (selectedFiles.length > 0) {
      const storageRef = storage.ref('products'); // Путь к папке, где хранятся фотографии товаров

      // Загрузите новые фотографии в Firebase Storage
      selectedFiles.forEach((file) => {
        const imageRef = storageRef.child(file.name); // Создайте ссылку на файл в Firebase Storage
        imageRef
          .put(file)
          .then((snapshot) => {
            setProgress('фото додано');
            // Получите URL загруженной фотографии из снимка (snapshot)
            // eslint-disable-next-line
            return snapshot.ref.getDownloadURL().then((downloadURL) => {
              // Добавьте новый URL к массиву фотографий в updatedProduct
              const updatedProduct = { ...editProduct };
              updatedProduct.img.push(downloadURL);

              // Обновите товар в Firestore
              return updateFirestoreProduct(updatedProduct);
            });
          })
          .catch((error) => {
            // Обработка ошибок загрузки изображения или других ошибок
            // eslint-disable-next-line
            console.error('Произошла ошибка при загрузке изображения:', error);
          });
      });
    } else {
      // Если новых фотографий нет, просто обновите товар в Firestore
      updateFirestoreProduct(editProduct);
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleUploadClick = () => {
    handleUpload('instagram', instagram, instagramLink);
    setProgressCarousel(true);
  };

  const handleUploadStock = () => {
    handleUpload('stock', stock, newStock);
    setProgressStock(true);
  };

  const handleUploadProductsClick = () => {
    handleUpload('products', arrayProduct, product);
    setProgressProduct(true);
  };

  return (
    <RequireAdminAuth>
      <div className="setting">
        <div className="setting-carusel container">
          <p className="setting-carusel__title">Зображення у instagram</p>
          <div className="setting-carusel__box">
            {instagram && instagram.map((item, index) => (
              <div key={index} className="setting-carusel__item">
                <img src={item.img[0]} className="img-cover" alt="..." />
                <button className="setting-carusel__item-delete" onClick={() => handleDelete(item.img, 'instagram', instagram, setProgressCarousel(true))}>Видалити</button>
              </div>
            ))}
          </div>

          <div className="setting-upload">
            <input className="setting-upload__input" type="file" onChange={handleFileChange} />
            <input className="setting-upload__input" type="text" placeholder="Посилання на товар в інстаграмі" name="linkInstagram" onChange={handleInputChangeInstagram} />
            <button onClick={handleUploadClick} className="setting-upload__button">Додати зображення</button>
          </div>
          {progressCarousel ? <div className="setting-carusel__progress">{progress}</div> : null}
        </div>

        {/* акции */}

        {stock && <div className="setting-carusel container">
          <p className="setting-carusel__title">Акції</p>
          <div className="setting-stock__box">
            {stock && stock.map((item, index) => (
              <div key={index} className="setting-stock__box__item">
                <div className='setting-stock__box__item-img'>
                  {item.img && <img src={item.img[0]} className="img-cover" alt="..." />}
                </div>
                <div className='setting-stock__box__item-text'>{item.text}</div>
                <div className='setting-stock__box__item-description'>{item.description}</div>
                <button className="setting-stock__box__item-delete" onClick={() => handleDelete(item.img, 'stock', stock, setProgressStock(true))}>Видалити</button>
              </div>
            ))}
          </div>

          <div className="stock-upload">
            <input className="setting-upload__input" type="file" onChange={handleFileChange} />
            <input
              className="setting-product__input"
              name="text"
              onChange={handleInputChangeStock}
              value={stock.text}
              type="text"
              placeholder="Назва акції"
            />
            <input
              className="setting-product__input"
              name="description"
              onChange={handleInputChangeStock}
              value={stock.description}
              type="text"
              placeholder="Опис акції"
            />
            <button onClick={handleUploadStock} className="setting-upload__button">Додати акцію</button>
          </div>
          {progressStock ? <div className="setting-carusel__progress">{progress}</div> : null}
        </div>}

        <div className="setting-product container">
          <p className="setting-product__title">{editProduct ? ' Редагування товару' : 'Управління товарами'}</p>

          <div className='setting-product__inputs-wrapper'>

            <div className="setting-product__inputs">
              <input type="file" onChange={handleFileChange} multiple />
              {/* {editProduct ? (
              <input
                className="setting-product__input"
                name="titleEn"
                onChange={(e) => handleEditInputChange(e, editProduct)}
                value={editProduct.titleEn}
                type="text"
                placeholder="Назва товару на En"
              />
            ) : (
              <input
                className="setting-product__input"
                name="titleEn"
                onChange={handleInputChange}
                value={product.titleEn}
                type="text"
                placeholder="Назва товару на En"
              />
            )} */}

              {editProduct ? (
                <input
                  className="setting-product__input"
                  name="titleUa"
                  onChange={(e) => handleEditInputChange(e, editProduct)}
                  value={editProduct.titleUa}
                  type="text"
                  placeholder="Назва товару"
                />
              ) : (
                <input
                  className="setting-product__input"
                  name="titleUa"
                  onChange={handleInputChange}
                  value={product.titleUa}
                  type="text"
                  placeholder="Назва товару"
                />
              )}

              {editProduct ? (
                <input
                  className="setting-product__input"
                  name="id" onChange={(e) => handleEditInputChange(e, editProduct)}
                  value={editProduct.id}
                  type="number"
                  placeholder="id товару" />
              ) : (
                <input className="setting-product__input"
                  name="id" onChange={handleInputChange}
                  value={product.id} type="number"
                  placeholder='id товару' />
              )}

              {editProduct ? (
                <input
                  className="setting-product__input"
                  name="article" onChange={(e) => handleEditInputChange(e, editProduct)}
                  value={editProduct.article}
                  type="number"
                  placeholder="Артикль товару" />
              ) : (
                <input className="setting-product__input"
                  name="article" onChange={handleInputChange}
                  value={product.article} type="number"
                  placeholder='Артикль товару' />
              )}

              {editProduct ? (
                <input
                  className="setting-product__input"
                  name="link"
                  onChange={(e) => handleEditInputChange(e, editProduct)}
                  value={editProduct.link}
                  type="text"
                  placeholder="Посилання на відео інстаграмі"
                />
              ) : (
                <input
                  className="setting-product__input"
                  name="link"
                  onChange={handleInputChange}
                  value={product.link}
                  type="text"
                  placeholder="Посилання на відео в інстаграмі"
                />
              )}

              {editProduct ? (
                <input
                  className="setting-product__input"
                  name="price" onChange={(e) => handleEditInputChange(e, editProduct)}
                  value={editProduct.price}
                  type="number"
                  placeholder="Ціна" />
              ) : (
                <input className="setting-product__input"
                  name="price" onChange={handleInputChange}
                  value={product.price} type="number"
                  placeholder='Ціна' />
              )}

              {editProduct ? (
                <select
                  onChange={(e) => handleEditInputChange(e, editProduct)}
                  name="category"
                  value={editProduct.category}>
                  <option>Обрати категорію</option>
                  {categoryArray.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              ) : (
                <select
                  onChange={handleInputChange}
                  name="category"
                  value={product.category}
                >
                  <option>Обрати категорію</option>
                  {categoryArray.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              )}

              {editProduct ? (
                <select
                  onChange={(e) => handleEditInputChange(e, editProduct)}
                  name="subСategories"
                  value={editProduct.subСategories}>
                  <option>Обрати підкатегорію</option>
                  {subСategoriesArray.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              ) : (
                <select
                  onChange={handleInputChange}
                  name="subСategories"
                  value={product.subСategories}
                >
                  <option>Обрати підкатегорії</option>
                  {subСategoriesArray.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              )}

              {editProduct ? (
                <select
                  onChange={(e) => handleEditInputChange(e, editProduct)}
                  name="brand"
                  value={editProduct.brand}>
                  <option>Обрати бранд</option>
                  {brands.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              ) : (
                <select
                  onChange={handleInputChange}
                  name="brand"
                  value={product.brand}
                >
                  <option>Обрати бранд</option>
                  {brands.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              )}

              {editProduct ? (
                <select
                  onChange={(e) => handleEditInputChange(e, editProduct)}
                  name="color"
                  value={editProduct.color}>
                  <option>Обрати колір</option>
                  {colors.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              ) : (
                <select
                  onChange={handleInputChange}
                  name="color"
                  value={product.color}
                >
                  <option>Обрати колір</option>
                  {colors.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              )}

              {editProduct ? (
                <select
                  onChange={(e) => handleEditInputChange(e, editProduct)}
                  name="size"
                  value={editProduct.size}>
                  <option>Обрати розмір</option>
                  {size.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              ) : (
                <select
                  onChange={handleInputChange}
                  name="size"
                  value={product.size}
                >
                  <option>Обрати розмір</option>
                  {size.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              )}

              {editProduct ? (
                <label className='setting-label'>
                  <input
                    className='checkbox'
                    name="popular"
                    type="checkbox"
                    onChange={(e) => handleEditInputChange(e, editProduct)}
                    checked={editProduct.popular}
                    value={editProduct.popular} />
                  Додати у популярні
                </label>
              ) : (
                <label className='setting-label'>
                  <input
                    className='checkbox'
                    name="popular"
                    type="checkbox"
                    onChange={handleInputChange}
                    value={product.popular} />
                  Додати у популярні
                </label>
              )}

              {editProduct ? (
                <button
                  className="setting-product__button"
                  onClick={() => handleEditProduct(editProduct)}>Зберегти</button>
              ) : (
                <button
                  onClick={handleUploadProductsClick}
                  className="setting-product__button">Додати товар</button>
              )}

            </div>

            {/* фотки */}
            {editProduct && <div className='setting-product__inputs-foto'>
              {editProduct.img.map((item, index) => (
                <div key={index} className='setting-product__inputs-foto-wrapper'>
                  <img className='img' src={item} alt="" />
                  <div onClick={() => handleEditImage(item)} className='setting-product__inputs-foto-wrapper-delete'>Х</div>
                </div>
              ))}
            </div>}

            <div className="setting-product__inputs__other">
              {editProduct ? (
                <textarea
                  rows={12}
                  className="setting-product__input"
                  name="characteristicUa"
                  onChange={(e) => handleEditInputChange(e, editProduct)}
                  value={editProduct.characteristicUa}
                  type="text"
                  placeholder="Характеристика товару" />
              ) : (
                <textarea
                  rows={12}
                  className="setting-product__input"
                  name="characteristicUa"
                  onChange={handleInputChange}
                  value={product.characteristicUa}
                  type="text"
                  placeholder='Характеристика товару' />
              )}

              {editProduct ? (
                <textarea
                  rows={12}
                  className="setting-product__input"
                  name="descriptionUa" onChange={(e) => handleEditInputChange(e, editProduct)}
                  value={editProduct.descriptionUa}
                  type="text"
                  placeholder="Опис товару" />
              ) : (
                <textarea
                  rows={12}
                  className="setting-product__input"
                  name="descriptionUa" onChange={handleInputChange}
                  value={product.descriptionUa}
                  type="text"
                  placeholder='Опис товару' />
              )}

            </div>

          </div>

          <div className="setting-carusel__box2">
            {progressProduct ? <div className="setting-carusel__progress">{progress}</div> : null}
          </div>
          <div className='setting-product__flex'>

            <p className="setting-product__flex-title">Товар</p>
            <select className='setting-product__select' onChange={handleChangeCategory}>
              <option>Всі товари</option>
              {categoryArray.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>

            <select className='setting-product__select' onChange={handleChangeBrands}>
              <option>Всі бренди</option>
              {brands.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>

            <select className='setting-product__select' onChange={handleChangeColors}>
              <option>Всі кольори</option>
              {colors.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>

            <label className='setting-product__change'>
              <input name="popular" type="checkbox" onChange={handleChangeShowPopular} />
              Показати популярні
            </label>

            <input className='setting-product__search' value={searchQuery} onChange={handleSearchInputChange} type="text" name="search" placeholder='Пошук товара за ім`ям' />

            <div className='setting-product__display'>
              <WindowIcon
                onClick={() => setDisplay(true)}
                sx={{ cursor: 'pointer' }}
              />
              <ViewStreamIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => setDisplay(false)}
              />
            </div>

          </div>
        </div>
        <div className={`${display ? 'setting-product__box' : 'setting-product__box__display'}`}>

          {displayedProducts.slice().reverse().map((item, index) => (
            <div key={index} className={`${display ? 'setting-product__box-items' : 'setting-product__box-items__display'}`}>
              <div className={`${display ? 'setting-product__box-item' : 'setting-product__box-item__display'}`}>
                <div className={`${display ? 'setting-product__box-item__picture' : 'setting-product__box-item__picture__display'}`}>
                  <img className='setting-product__box-item__img' src={item.img[0]} alt={item.img[0]} />
                </div>
                <div className={`${display ? 'setting-product__box-item-info' : 'setting-product__box-item-info__display'}`}>
                  <div className={`${display ? 'setting-product__box-item-info__title color-md-one' : 'setting-product__box-item-info__title__display color-md-one'}`}>{item.titleUa}</div>
                  {/* <div className={`${display ? 'setting-product__box-item-info__title color-md-two' : 'setting-product__box-item-info__title__display color-md-two'}`}>EN: {item.titleEn}</div> */}
                  <div className={`${display ? 'setting-product__box-item-info__price' : 'setting-product__box-item-info__price__display'}`}>{item.price} гр</div>
                  <div className={`${display ? 'setting-product__box-item-info__description' : 'setting-product__box-item-info__description__display'}`}>Категорія: {item.category}</div>
                  <div className={`${display ? 'setting-product__box-item-info__article' : 'setting-product__box-item-info__article__display'}`}>Артикль: {item.article}</div>
                  <div className={`${display ? 'setting-product__box-item-info__article' : 'setting-product__box-item-info__article__display'}`}>Id: {item.id}</div>
                  <div className={`${display ? 'setting-product__box-item-info__article color-md-three' : 'setting-product__box-item-info__article__display color-md-three'}`}>{item.brand}</div>
                  <div className={`${display ? 'setting-product__box-item-info__popular' : 'setting-product__box-item-info__popular__display'}`}>Популярні: {item.popular ? 'Так' : 'Ні'}</div>
                </div>
              </div>
              <button className="setting-carusel__item-edit" onClick={() => setEditProduct(item, setEditProductOrigin(item))}>Редагувати</button>
              {/* <button className="setting-carusel__item-edit" onClick={() => resetForm()}>Редагувати</button> */}
              <button className="setting-carusel__item-delete" onClick={() => handleDelete(item.img, 'products', arrayProduct, setProgressProduct(true))}>Видалити</button>
            </div>
          ))}

        </div>
      </div>
    </RequireAdminAuth>
  );
}
