import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css';

export default function NotFound() {
  return (
    <div className='not-found'>
      <div className='not-found-title'>Такої сторінки немає ...</div>
      <Link to='/' className='not-found-lonk'>перейти на головну</Link>
    </div>
  );
}
