import React from 'react';
import Top from '../../components/Top/Top';
import Instagram from '../../components/Instagram/Instagram';
import './About.css';

export default function About() {
  return (
    <>
      <div className='about'>
        <div className='about-cloud'>
          <img className='img' src="./img/clouds.png" alt="хмара" />
        </div>

        <div className='about-image-one'>
          <img className='img' src="./img/star.svg" alt="зірка" />
        </div>

        <div className='about-image-two'>
          <img className='img' src="./img/star.svg" alt="зірка" />
        </div>

        <div className='about-image-three'>
          <img className='img' src="./img/star.svg" alt="зірка" />
        </div>

        <div className='about-image-four'>
          <img className='img' src="./img/star.svg" alt="зірка" />
        </div>

        <h3 className='pay-info__title'>Про нас</h3>
        <div className='pay-info__line'>
          <img className='img' src="./img/line.svg" alt="хвиля" />
        </div>

        <h2 className='about-description'>Вітаємо Вас у магазині дитячих меблів Tuttikids!</h2>

        <div className='about-text'>
          <p>Наша команда з радістю вітає мам, тат і майбутніх батьків. Вже 15 років ми створюємо затишний простір для дітей. За цей час нашими клієнтами стали тисячі родин.</p>
          <p>Ми добре розуміємо, що дитячі меблі мають поєднувати красу з функціональністю та безпекою. У нас Ви знайдете найкращі рішення для дитячої кімнати, що задовольнять
            Ваші потреби, вимоги й естетичні смаки. Tuttikids пропонує меблі перевірених українських виробників з якісних та екологічних матеріалів для комфорту та здоров`я Вашої дитини.</p>
          <p>Дитяча безпека – найважливіше. Тому наші меблі та матраци не просто красиві. Вони відповідають усім вимогам безпеки: мають заокруглені кути, безпечні кріплення та нуль шкідливих речовин.</p>
          <p>Обираючи вироби для дитячої кімнати у нас, Ви отримуєте якість, надійність, безпеку та стиль. Ми завжди поруч і готові допомогти Вам у виборі, відповісти на запитання та зробити процес покупки зручним і приємним.
            Приходьте до Tuttikids та довірте нам затишок і безпеку Вашого малюка!</p>
        </div>

        <a className='about-text__link' href='https://www.instagram.com/stories/highlights/17903773234733068/' target='blank'>Ось відгуки батьків, які вже це зробили перехід на відгуки</a>

        <div className='about-day'>
          <img className='img' src="./img/day.svg" alt="have a nice day" />
        </div>

      </div>
      <Top popular={true} />
      <Instagram />
    </>
  );
}
